import { defaults as _defaults } from 'lodash-es'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const CACHED_PROMISES: Record<string, Promise<any>> = {}

interface LoqateBaseResponse<T extends LoqateResponseItem> {
  Items: T[]
}

interface LoqateResponseItem {
}

interface LoqateError extends LoqateResponseItem {
  Error: string;
  Description: string;
  Cause: string;
  Resolution: string;
}

export interface LoqateEmailValidationResponse extends LoqateResponseItem {
  /**
   * Example response: Valid
   * Possible responses
   * Valid - The email address has been fully validated (including the account portion)
   * Valid_CatchAll - The domain has been validated but the account could not be validated
   * Invalid - The email address is invalid and shouldn't be accepted
   * Timeout - The validation could not be completed within the timeout specified (try increasing the timeout value)
   * */
  ResponseCode: string;
  /** Example response Valid
   * A textual description of the ResponseCode returned */
  ResponseMessage: string;
  /** testing@test123.com
   * The email address that verification was attempted on. */
  EmailAddress: string;
  /** testing
   * The account portion of the email address provided. */
  UserAccount: string;
  /** test123.com
   * The domain portion of the email address provided. */
  Domain: string;
  /** False
   * Whether the email address provided is a disposable mailbox (some companies create temporary mailboxes which shouldn't be used for marketing communications). */
  IsDisposableOrTemporary: boolean;
  /** True
   * True if we recognise the email address against known lists of complainers and/or the email address has been used to defraud. */
  IsComplainerOrFraudRisk: boolean;
  /** 0.526
   * The duration (in seconds) that the email validation took (maximum timeout enforced at 15 seconds).
   * We recommend a high timeout (at least 5 seconds) value as it will minimise the number of "Timeout" responses returned. */
  Duration: number;
}

/**
 * @see https://www.loqate.com/resources/support/apis/BankAccountValidation/Interactive/RetrieveBySortcode/1/
 */
export interface LoqateBankValidationResponse extends LoqateResponseItem {
  IsCorrect: boolean;
  IsDirectDebitCapable: boolean;
  StatusInformation: string;
  CorrectedSortCode: string;
  CorrectedAccountNumber: string;
  IBAN: string;
  /** The name of the banking institution. */
  Bank: string;
  /** The banking institution's BIC, also know as the SWIFT BIC. */
  BankBIC: string;
  /** The name of the account holding branch. */
  Branch: string;
  /** The branch's BIC. */
  BranchBIC: string;
  /** Line 1 of the branch's contact address. NB: This is the address to be used for BACs enquiries and may be a contact centre rather than the branch's address. */
  ContactAddressLine1: string;
  /** Line 2 of the branch's contact address. */
  ContactAddressLine2: string;
  /** The branch's contact post town. */
  ContactPostTown: string;
  /** The branch's contact postcode. */
  ContactPostcode: string;
  /** The branch's contact phone number. */
  ContactPhone: string;
  /** The branch's contact fax number. */
  ContactFax: string;
  /** Indicates that the account supports the faster payments service. */
  FasterPaymentsSupported: boolean;
  /** Indicates that the account supports the CHAPS service. */
  CHAPSSupported: boolean;
}

const cachePromise = <T>(key: string, promiseGenerator: () => Promise<T>) => {
  if (typeof CACHED_PROMISES[key] === 'undefined') {
    CACHED_PROMISES[key] = promiseGenerator()
      .catch(error => {
        delete CACHED_PROMISES[key]
        throw error
      })
  }
  return CACHED_PROMISES[key] as Promise<T>
}

/**
 * This class implements a proxy to loqate email & bank validations
 */
export default class LoqateApi {
  constructor ({ Key, Timeout = 5000 }: {Key: string, Timeout?: number}) {
    this._defaults = {
      Key
    }
    this.EmailTimeout = Timeout
    this._client = axios.create({
      baseURL: 'https://api.addressy.com/'
    })
  }

  readonly _defaults: Readonly<{Key: string}>
  readonly EmailTimeout: number
  private readonly _client: AxiosInstance

  get key () {
    return this._defaults.Key
  }

  validate (Email: string) {
    return cachePromise(`EMAIL_${Email}`, () =>
      this.fetch<LoqateEmailValidationResponse>('EmailValidation/Interactive/Validate/v2.00', {
        params: {
          Email,
          Timeout: this.EmailTimeout
        }
      }).then(response => response.Items[0])
    )
  }

  bankBySortCode (SortCode: string) {
    return cachePromise(`BANK_${SortCode}`, () =>
      this.fetch<LoqateBankValidationResponse>('BankAccountValidation/Interactive/RetrieveBySortcode/v1.00', {
        params: {
          SortCode
        }
      }).then(response => response.Items[0])
    )
  }

  // no UI ever implemented for addresses
  // searchAddresses (query: string, opts: any = {}) {
  //   return cachePromise(`ADDRESS_${query}_${JSON.stringify(opts)}`, () =>
  //     this.fetch('Capture/Interactive/Find/v1.00', {
  //       params: {
  //         ...(query ? { Text: query } : {}),
  //         Countries: ['GB'],
  //         ...opts
  //       }
  //     }).then(response => response.Items)
  //   )
  // }

  // getAddress (addressId: string) {
  //   return cachePromise(`ADDRESS_ID_${addressId}`, () =>
  //     this.fetch('Capture/Interactive/Retrieve/v1.00', {
  //       params: {
  //         Id: addressId
  //       }
  //     })
  //   )
  // }

  async fetch <T extends LoqateResponseItem> (endPoint: string, options: AxiosRequestConfig) {
    _defaults(options.params, this._defaults)
    let response = await this._client.get<LoqateBaseResponse<T | LoqateError>>(`${endPoint}/json3ex.ws`, options).then(r => r.data)
    if (response.Items[0] && (response.Items[0] as LoqateError).Error) {
      throw new Error(`Error: ${(response.Items[0] as LoqateError).Description}`)
    }
    return response as LoqateBaseResponse<T>
  }
}
